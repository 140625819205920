<template>
  <base-material-card icon="mdi-badge-account-horizontal-outline">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t('gestion_des_evenements.edition_evenement.inscriptions.titre_inscriptions') }}
      </div>
    </template>
    <v-text-field
      v-model="mots"
      append-icon="mdi-magnify"
      class="ml-auto"
      l:abel="$t('general.chercher')"
      hide-details
      single-line
      style="max-width: 250px;"
    />

    <v-divider class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="inscriptions"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"
      :search.sync="search"
        :footer-props="{
                  itemsPerPageOptions: [10,50,100,500],
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
    >
      <template v-slot:[`item.dateCreation`]="{ item }">
        {{ formaterDate(item.dateCreation) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="px-2 ml-1 warning"
          min-width="0"
          small
          @click="onEffacerInscription(item)"
        >
          <v-icon
            small
          >
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <selection-usager
      :libelle="$t('gestion_des_evenements.edition_evenement.inscriptions.creer_nouvelle_inscription')"
      :usagers-inscrits="toutesLesInscriptions.map(i => i.usager.id)"
      @usager-selection="onUsagerIdentifié($event)"
    />
  </base-material-card>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import SelectionUsager from './../../composantes/SelectionUsager'
  import i18n from '@/i18n.js'

  export default {
    components: {
      SelectionUsager,
    },
    props: {
      evenement: Object,
    },
    data: () => ({
      loading: true,
      count: 0,
      mots: '',
      search: undefined,
      inscriptions: [],
      toutesLesInscriptions: [],
      options: {},
      headers: [
        { text: i18n.t('gestion_des_evenements.edition_evenement.inscriptions.prenom'), value: 'usager.prenom' },
        { text: i18n.t('gestion_des_evenements.edition_evenement.inscriptions.nom'), value: 'usager.nom' },
        { text: i18n.t('gestion_des_evenements.edition_evenement.inscriptions.courriel'), value: 'usager.courriel' },
        { text: i18n.t('date'), value: 'dateCreation' },
        { text: i18n.t('gestion_des_evenements.edition_evenement.inscriptions.par'), value: 'creePar' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
    }
    ),
    watch: {
      evenement: function (e) {
        if (e) {
          this.getData()
        }
      },
      options: {
        handler () {
          if (this.evenement.id) { this.getData() }
        },
        deep: true,
      },
      mots: {
        handler () {
          this.getData()
        },
        deep: true,
      },

    },
    methods: {
      getData () {
        if (!this.evenement.id) {
          // très étrange que ça arrive.
          return
        }
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const s = sortDesc[0] ? ',desc' : ''
        const arg = `size=${itemsPerPage}&page=${page - 1}&sort=${sortBy + s}`
        restApiService
          .get(`/api/evenements/${this.evenement.id}/inscriptions?${arg}&mots=${this.mots}`)
          .then((result) => {
            this.inscriptions = result.data
            console.log(result.data, 'result.data')
            this.count = parseInt(result.headers['x-total-count'])
            this.loading = false
          })
          .catch((erreur) => {
            alert(erreur)
            this.loading = false
          })

        this.getToutesLesInscription()
      },
      onEffacerInscription (ins) {
        restApiService
          .delete('/api/inscriptions/' + ins.id)
          .then((result) => {
            this.getData()
          })
          .catch((erreur) => {
            alert(erreur)
          })
      },
      getToutesLesInscription() {
        const args = 'size=2147483647&page=0'
        restApiService
          .get(`/api/evenements/${this.evenement.id}/inscriptions?${args}`)
          .then((result) => {
            console.log(result.data, 'toutes les inscriptions')
            this.toutesLesInscriptions = result.data
          })
      },
      onUsagerIdentifié (u) {
        const inscription = {
          creePar:
            this.$store.state.userInfo.prenom + ' ' + this.$store.state.userInfo.nom,
          evenement: {
            id: this.evenement.id,
          },
          usager: {
            id: u.id,
          },
        }
        restApiService
          .post('/api/inscriptions', inscription)
          .then((result) => {
            this.getData()
          })
          .catch((erreur) => {
            alert(erreur)
          })
      },
    },
  }
</script>
