<template>
  <v-text-field
    v-model="dateTime"
    type="datetime-local"
    :label="label"
    data-cy="tfDateTime"
    max="9999-12-30T23:59"
  />
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'CnxDateTime',
    props: {
      value: [String, Object],
      label: String,
    },
    data () {
      return {
        dateTime: '',
      }
    },
    watch: {
      value: {
        handler (newValue) {
          this.dateTime = moment(newValue).format('YYYY-MM-DDTHH:mm')
        },
      },
      dateTime: {
        handler (newValue) {
          this.$emit('update:value', moment(newValue))
        },
      },
    },
    mounted () {
    },
  }
</script>
