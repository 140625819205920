<template>
  <v-container
    id="usager-profile"
    fluid
    tag="section"
    data-cy="pageEditionEvenement"
  >
    <base-material-card icon="mdi-calendar">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t('gestion_des_evenements.edition_evenement.evenement') }}
        </div>
      </template>

      <v-form>
        <v-row justify="center">
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-if="isAdmin"
              id="selectNomCompteClient"
              v-model="evenement.compteClient.id"
              data-cy="selectNomCompteClient"
              :items="listeComptesClients"
              :label="$t('gestion_des_evenements.edition_evenement.compte_client')"
              item-text="nom"
              item-value="id"
            />
            <v-text-field
              v-model="evenement.nom"
              class="purple-input"
              :label="$t('nom_commun')"
              data-cy="tfNom"
            />
            <cnx-date-time
              :value.sync="evenement.dateDebut"
              :label="$t('gestion_des_evenements.edition_evenement.date_debut')"
              data-cy="dtDateDebut"
            />
            <cnx-date-time
              :value.sync="evenement.dateFin"
              :label="$t('gestion_des_evenements.edition_evenement.date_fin')"
              data-cy="dtDateFin"
            />
            <v-row>
              <v-col>
                {{ $t('description') }} :
                <cnx-editeur
                  data-cy="ckeDescription"
                  :texte.sync="evenement.description"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ $t('gestion_des_evenements.edition_evenement.info_pour_soutien') }}
                <cnx-editeur
                  :texte.sync="evenement.infoSoutien"
                  data-cy="ckeInfoSoutien"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <boite-media
              :media.sync="mediaImage"
            />
            {{ $t('gestion_des_evenements.edition_evenement.programme') }}
            <cnx-editeur
              :texte.sync="evenement.infoProgramme"
            />
            <br>
            {{ $t('gestion_des_evenements.edition_evenement.les_conferenciers') }}
            <cnx-editeur
              :texte.sync="evenement.infoConferencier"
              data-cy="ckeConferenciers"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            class="mr-0"
            data-cy="btnSauvegarder"
            @click="onSauvegarder()"
          >
            {{ $t('general.sauvegarder') }}
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>
    <inscriptions-evenement
      :evenement="evenement"
      data-cy="suCreerInscription"
    />

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card icon="mdi-human-male-board">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('conferences') }}
            </div>
          </template>
          <v-data-table
            :items="evenement.listeConferences"
            :headers="conferenceHeaders"
            :sort-by.sync="conferenceSortByDefaut"
            :footer-props="{
              itemsPerPageText: $t('lignes_par_page'),
              itemsPerPageAllText: $t('tout'),
              pageText: `{0}-{1} ${$t('general.de')} {2}`
            }"
          >
            <template v-slot:[`item.dateDebut`]="{ item }">
              {{ formaterDate(item.dateDebut) }}
            </template>
            <template v-slot:[`item.dateFin`]="{ item }">
              {{ formaterDate(item.dateFin) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="px-2 ml-1 primary"
                min-width="0"
                small
                @click="$router.push({path: '/gestion/editionConference/'+item.id})"
              >
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1 warning"
                min-width="0"
                small
                @click="onSupprimerConference(item)"
              >
                <v-icon
                  small
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-btn
            data-cy="btnAjouterConference"
            @click="onAjouterConference()"
          >
            {{ $t('gestion_des_evenements.edition_evenement.les_conferences.ajouter_conference') }}
          </v-btn>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card icon="mdi-face-man-profile">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('kiosques') }}
            </div>
          </template>
          <v-data-table
            :items="evenement.listeAffectationsKiosques"
            :headers="kiosqueHeaders"
            data-cy="dtKiosques"
              :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="px-2 ml-1 primary"
                min-width="0"
                small
                @click="$router.push({path: '/gestion/editionKiosque/'+item.kiosque.id})"
              >
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1 warning"
                min-width="0"
                small
                @click="onRetirerKiosque(item)"
              >
                <v-icon
                  small
                >
                  mdi-minus
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-select
            v-model="selectionAffectationKiosque"
            :items="listeKiosquesDisponibles"
            item-text="nom"
            return-object
            data-cy="sKiosque"
          />
          <v-btn
            data-cy="btnAffecterKiosque"
            @click="onAffecterKiosque()"
          >
            {{ $t('gestion_des_evenements.les_kiosques.affecter_ce_kiosque') }}
          </v-btn>
        </base-material-card>
      </v-col>
    </v-row>

    <v-btn
      x-large
      color="primary"
      @click="onSupprimerDonneesUsager"
    >
      Supprimer les données usager
    </v-btn>
  </v-container>
</template>

<script>

  import restApiService from '@/services/restApiService.js'
  import BoiteMedia from './../../composantes/BoiteMedia'
  import InscriptionsEvenement from './InscriptionsEvenement'
  import i18n from '@/i18n.js'
  import CnxDateTime from './../../composantes/CnxDateTime'
  import CnxEditeur from './../../composantes/CnxEditeur'

  export default {
    components: {
      BoiteMedia,
      InscriptionsEvenement,
      CnxEditeur,
      CnxDateTime,
    },
    data: () => ({
      isAdmin: false,
      evenement: {
        compteClient: {
          id: undefined,
        },
      },
      mediaImage: {},
      conferenceHeaders: [
        { text: i18n.t('nom_commun'), value: 'nom' },
        { text: i18n.t('debut'), value: 'dateDebut' },
        { text: i18n.t('fin'), value: 'dateFin' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
      conferenceSortByDefaut: ['dateDebut', 'nom'],
      kiosqueHeaders: [
        { text: i18n.t('nom_commun'), value: 'kiosque.nom' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
      listeComptesClients: [],
      selectionAffectationKiosque: null,
      listeKiosquesDisponibles: [],
    }),
    mounted () {
      this.isAdmin = this.$store.state.userInfo.roles === 'admin'

      this.chargerEvenement()

      if (this.isAdmin) {
        restApiService
          .get('/api/compte-clients')
          .then((result) => {
            this.listeComptesClients = result.data
            this.loading = false
          })
          .catch((erreur) => alert(erreur))
      }
    },
    methods: {
      chargerEvenement () {
        restApiService
          .get(`/api/evenements/${this.$route.params.idEvenement}`)
          .then((result) => {
            this.evenement = result.data
            if (this.evenement.mediaImage) {
              restApiService.getMedia(this.evenement.mediaImage).then((m) => {
                this.mediaImage = m.data
              })
            }
            const arg = `size=${200}&page=${0}&sort=&mots=`
            restApiService
              .get(`/api/compte-clients/${this.$store.state.compteClient.id}/kiosques?${arg}`)
              .then((result) => {
                console.log('tous mes kiosques', result)
                this.listeKiosquesDisponibles = result.data.filter(k => {
                  const existe = !this.evenement.listeAffectationsKiosques.find(aff => aff.kiosque.id === k.id)
                  return existe
                })
              })
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarder () {
        restApiService
          .put('/api/evenements', this.evenement)
          .then(() => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch((erreur) => alert(erreur))

        if (Object.keys(this.mediaImage).length) {
          restApiService
            .put(`/api/evenements/${this.evenement.id}/media`, this.mediaImage)
            .then(() => {
            })
            .catch((erreur) => alert(erreur))
        }
      },
      async onAjouterConference () {
        const conference = {
          nom: '',
          evenement: {
            id: this.evenement.id,
          },
        }
        const reponse = await this.$dialog.prompt({
          title: i18n.t('gestion_des_evenements.edition_evenement.les_conferences.creer_nouvelle_conference'),
          text: i18n.t('gestion_des_evenements.edition_evenement.les_conferences.saisir_nom_conf'),
          rules: [
            v => !!v || i18n.t('champ_requis'),
          ],
          textField: {
            type: 'text',
            id: 'nouvelleConferenceNomlInput',
          },
          actions: {
            false: i18n.t('annuler'),
            true: {
              color: 'red',
              text: i18n.t('confirmer'),
              handle: () => {
                return new Promise(resolve => {
                  conference.nom = document.getElementById('nouvelleConferenceNomlInput').value
                  if (conference.nom) {
                    resolve(true)
                  } else { resolve(false) }
                })
              },
            },
          },
        }).catch(() => {
          console.log('Prompt dismissed')
        })

        if (reponse) {
          restApiService
            .post('/api/conferences', conference)
            .then((result) => {
              this.$router.push({ path: '/gestion/editionConference/' + result.data.id + '?nouveau=true' })
            })
            .catch((erreur) => alert(erreur))
        }
      },
      onAffecterKiosque () {
        const affectation = {
          kiosque: {
            id: this.selectionAffectationKiosque.id,
          },
          evenement: {
            id: this.evenement.id,
          },
        }
        restApiService
          .post('/api/aff-evenement-kiosques', affectation)
          .then((result) => {
            this.chargerEvenement()
            this.selectionAffectationKiosque = null
          })
          .catch((erreur) => alert(erreur))
      },
      onSupprimerConference (item) {
        this.$dialog.confirm({
          text: i18n.t('gestion_des_evenements.edition_evenement.confirmation_suppression_evenement'),
          title: this.$t('attention'),
        }).then((r) => {
          if (r) {
            restApiService
              .delete('/api/conferences/' + item.id)
              .then(() => {
                this.chargerEvenement()
              })
              .catch((erreur) => alert(erreur))
          }
        })
      },
      onRetirerKiosque (item) {
        this.$dialog.confirm({
          text: i18n.t('gestion_des_evenements.edition_evenement.confirmation_suppression_evenement'),
          title: this.$t('attention'),
        }).then((r) => {
          if (r) {
            restApiService
              .delete('/api/aff-evenement-kiosques/' + item.id)
              .then(() => {
                this.chargerEvenement()
              })
              .catch((erreur) => alert(erreur))
          }
        })
      },

      onSupprimerDonneesUsager() {
        if (!window.confirm('Êtes-vous certain de vouloir supprimer les données?')) return

        if (!window.confirm('Pas de retour en arrière..?')) return

        restApiService.post(`/api/supprimer-donnees-usager/${this.evenement.id}`)

        window.alert('Début de la suppression')
      },
    },
  }
</script>
